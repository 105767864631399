import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Grid, Box, Paper, Stack, Typography, Button } from "@mui/material"
import { useStaticQuery, graphql } from "gatsby"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"
import { StaticImage } from "gatsby-plugin-image"

const Fformen = props => {
  const { goodsHeaderId, companyId, company, goodsList } = props
  const [goodsId, setGoodsId] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)

  const onChangeSelect = e => {
    setGoodsId(e.target.value)
  }

  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "top/fformen.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 250, layout: FIXED)
        }
      }
    }
  `)

  useEffect(() => {
    goodsId > 0 ? setBtnDisable(false) : setBtnDisable(true)
  }, [goodsId])
  return (
    <>
      <Box sx={{ marginTop: 3, marginLeft: 1, marginRight: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>
              <StaticImage
                src="../../assets/images/top/f_header.png"
                width={960}
                alt={`お電話でのご注文・お問い合わせは${company.phone}`}
                placeholder="blurred"
                quality="65" // 画質
              />
            </h1>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={3} sx={{ marginTop: 3, marginLeft: 1, marginRight: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 2,
                m: 2,
                // backgroundColor: "#F1F1EE",
                backgroundColor: "#ffffff",
              }}
            >
              <img
                style={{ width: "100%" }}
                src={data.img1.publicURL}
                alt="F-formen"
              />
              <FormControl sx={{ width: "100%", mt: 3 }}>
                <form method="post" action={process.env.CART_URL}>
                  <input type="hidden" name="q" value="1" />
                  <input type="hidden" name="m" value="1" />
                  <input type="hidden" name="h" value={goodsHeaderId} />
                  <input type="hidden" name="i" value={goodsId} />
                  <input type="hidden" name="company_id" value={companyId} />
                  <input
                    type="hidden"
                    name="back_url"
                    value="https://body-custom.net/fformen"
                  />
                  <input
                    type="hidden"
                    name="deliver_trader_id"
                    value={process.env.TRADER_ID}
                  />
                  <InputLabel id="goods_id">お選びください</InputLabel>
                  <Select
                    labelId="goods_id_label"
                    id="goods_id"
                    label="お選びください"
                    required={true}
                    value={goodsId}
                    sx={{ width: "100%" }}
                    onChange={e => onChangeSelect(e)}
                  >
                    {goodsList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label.replace("null", "")}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ textAlign: "right" }}>
                    バリエーションを選んでください
                  </FormHelperText>

                  <div className="text-center p-2">
                    <Button
                      type="submit"
                      variant="contained"
                      color="warning"
                      startIcon={<AddShoppingCartIcon />}
                      disabled={btnDisable}
                    >
                      購入へ
                    </Button>
                  </div>
                </form>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                m: 2,
                p: 2,
                backgroundColor: "#F1F1F1",
              }}
            >
              <Stack spacing={3}>
                <Typography variant="h4">F-Formen</Typography>
                <Typography>
                  <span className="font-semibold">詳細</span>
                  ：日々の生活に香りを取り入れ、気持ちを豊かにするフレグランスアイテム。夏風の様に爽やかで柑橘系の香りは、時間の経過と共に甘く落ち着きのある安心感ある香りに。
                </Typography>
                <Typography>
                  <span className="font-semibold">送料</span>
                  ：2本セット以上のご注文で送料が無料になります。
                </Typography>
                <Typography>
                  <span className="font-semibold">内容量</span>：8ml
                </Typography>
                <Typography>
                  <span className="font-semibold">原材料</span>
                  ：エタノール、水、PEG40水添ヒマシ油、香料
                </Typography>
                <Typography>
                  <span className="font-semibold">ご利用方法</span>
                  ：手首、くるぶし、膝の裏などに適量をご利用ください。
                </Typography>
                <Typography>
                  <span className="font-semibold">使用上の注意</span>
                  ：お肌に異常が生じていないかよく注意して使用してください。お肌に合わない場合は、ご使用をおやめください。
                  傷やはれもの、しっしん等、異常のある部位にはお使いにならないでください。
                  極端に高温、又は低温の場所、直射日光の当たる場所には保管しないでください。
                </Typography>
                <Typography>
                  <span className="font-semibold">保存方法</span>
                  ：高温多湿、直射日光を避けて保管してください。可燃性のため、保管及び取り扱いあたっては火気に十分注意してください。
                </Typography>
              </Stack>
            </Box>
            <Typography textAlign="right" className="mb-5">
              <Link to="/sc" className="mr-5 mb-5">
                <Button variant="outlined" href="#outlined-buttons">
                  トップページへ戻る
                </Button>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Fformen
